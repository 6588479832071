import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFeedbackForSituationInstance, getSituationInstanceById } from "../../../service/situationService";
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonText, IonSpinner, IonButton, IonCardSubtitle } from "@ionic/react";
import { SituationInstance } from "../../../types/ai_pivot/situationTypes";
import ReactMarkdown from 'react-markdown';

const FeedbackListComponent: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [situationInstance, setSituationInstance] = useState<SituationInstance | null>(null);
    const [feedbackData, setFeedbackData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchSituationInstanceAndFeedback = async () => {
            try {
                const [instanceData, feedbackResult] = await Promise.all([
                    getSituationInstanceById(Number(id)),
                    getFeedbackForSituationInstance(Number(id))
                ]);

                setSituationInstance(instanceData);
                setFeedbackData(feedbackResult.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSituationInstanceAndFeedback();
    }, [id]);

    if (loading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <IonSpinner name="crescent" />
            </div>
        );
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Feedback</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
            <div className="public-feedback" style={{ width: '100%', maxWidth: '550px', margin: 'auto', overflowY: 'auto' }}>
                {situationInstance && (
                    <IonCard mode = 'ios' className="custom-ion-card">
                        <IonCardHeader>
                            <IonCardTitle>{situationInstance.situation.title}</IonCardTitle>
                            <IonCardSubtitle>{new Date(situationInstance.start_time).toLocaleString()}</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <ReactMarkdown>{situationInstance.feedback_as_text}</ReactMarkdown>
                        </IonCardContent>
                    </IonCard>
                )}
                {feedbackData.length > 0 ? feedbackData.map((fb, index) => (
                    <IonCard mode = 'ios' key={index} >
                        <IonCardHeader>
                            <IonCardTitle>Feedback by: @{fb.username}</IonCardTitle>
                            <IonCardSubtitle>Created at: {new Date(fb.created_at).toLocaleString()}</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonText>
                                <strong>Went well:</strong>
                                <ReactMarkdown>{fb.went_well}</ReactMarkdown>
                            </IonText><br />
                            <IonText>
                                <strong>To improve:</strong>
                                <ReactMarkdown>{fb.to_improve}</ReactMarkdown>
                            </IonText><br />
                            <IonText>
                                <strong>Came across as:</strong>
                                <ReactMarkdown>{fb.came_across_as}</ReactMarkdown>
                            </IonText>
                        </IonCardContent>
                    </IonCard>
                )) : (
                    <IonText>No feedback available</IonText>
                )}
            </div>    
            </IonContent>
        </IonPage>
    );
}

export default FeedbackListComponent;
