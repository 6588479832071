import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonPopover, IonToolbar } from "@ionic/react";
import { Lesson, UserProgress } from "../../../../types/ai_pivot/skillTypes";
import { close } from "ionicons/icons";
import { useState,useEffect } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { updateUserProgress, loadUserProgress } from "../../../../service/skillService";
import LessonButton from "./LessonButton";
import LessonSections from "./LessonSections";

const LessonComponent: React.FC<{
    lesson: Lesson,
    updateUserProgressUI: (progress: UserProgress) => void,
    isCompleted: boolean,
    isLocked: boolean,
    index: number,
    total: number,
    firstIncompleteLessonId: number
}> = ({ lesson, updateUserProgressUI, isCompleted, isLocked, index, total, firstIncompleteLessonId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuth();
    const [currentProgress, setCurrentProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isReviewing, setIsReviewing] = useState(false);

    useEffect(() => {
        if (user) {
            setIsLoading(true);
            loadUserProgress(user.id).then(({ data, error }) => {
                if (error) {
                    console.error(error);
                } else if (data) {
                    const userLessonProgress = data.find(
                        (prog) => prog.lesson_id === lesson.lesson_id
                    );
                    if (userLessonProgress) {
                        console.log("Progress:", userLessonProgress)
                        setCurrentProgress(userLessonProgress.progress || 0);
                    }
                }
                setIsLoading(false);
            });
        }
    }, [user, lesson.lesson_id]);

    const handleCompleteSituation = () => {
        if (user && !isReviewing) {
            updateUserProgress(user.id, lesson.skill_id, lesson.lesson_id, true, total).then(({ data, error }) => {
                if (error) {
                    console.error(JSON.stringify(error));
                } else if (data) {
                    updateUserProgressUI(data);
                }
            });
        }
    }

    const handleProgressUpdate = (progress: number) => {
        if (!isReviewing) {
            setCurrentProgress(progress);
            if (user) {
                updateUserProgress(user.id, lesson.skill_id, lesson.lesson_id, false, progress).then(({ data, error }) => {
                    if (error) {
                        console.error(JSON.stringify(error));
                    } else if (data) {
                        updateUserProgressUI(data);
                    }
                });
            }
        }
    }
    const handleStartLesson = () => {
        if (isCompleted) {
            setIsReviewing(true);
        }
        setIsOpen(true);
    }

    const showProgress = lesson.lesson_id === firstIncompleteLessonId && !isCompleted && !isReviewing;
    
    return (
        <>
            <LessonButton
                type={lesson.lesson_type}
                id={`${lesson.lesson_id}`}
                isCompleted={isCompleted}
                isLocked={isLocked}
                index={index}
                total={total}
                progress={currentProgress}
                showProgress={showProgress}
                
            />
            <IonPopover trigger={`${lesson.lesson_id}`} style={{ '--width': '80vw' }} mode="ios" side="bottom">
                <div className="ion-padding">
                    <div>{lesson.lesson_name}</div>
                    {!isLocked && !isLoading && (
                        <IonButton expand="block" onClick={handleStartLesson}>
                            {isCompleted ? 'Review Lesson' : 'Continue Lesson'}
                        </IonButton>
                    )}
                    {isLoading && <div>Loading...</div>}
                </div>
            </IonPopover>
            <IonModal
                isOpen={isOpen}
                canDismiss={true}
                onDidDismiss={() => {
                    setIsOpen(false);
                    setIsReviewing(false);
                }}
                mode="ios"
                style={{
                    '--min-width': '100%',
                    '--min-height': '100%',
                    '--border-radius': '0px'
                }}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => {
                                setIsOpen(false);
                                setIsReviewing(false);
                            }}><IonIcon icon={close}></IonIcon></IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div style={{ width: '100%' }}>
                        <LessonSections
                            modules={lesson.modules}
                            handleCompletion={handleCompleteSituation}
                            closeLesson={() => {
                                setIsOpen(false);
                                setIsReviewing(false);
                            }}
                            updateProgress={handleProgressUpdate}
                            // initialProgress={startFromBeginning ? 0 : currentProgress} 
                            initialProgress={isReviewing ? 0 : currentProgress}
                        />
                    </div>
                </IonContent>
            </IonModal>
        </>
    );
}

export default LessonComponent;