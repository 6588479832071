import { IonContent, IonModal, IonPage } from "@ionic/react";
import GatherUserInitialInfoComponent from "./InitialProfileComponent";
import '../../../theme/layout.css';
import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from "react";

const GatherInitialUserDataPopUp: React.FC = () => {
    const { userProfile } = useAuth();
    const [show, setShow] = useState(false);
    const [step, setStep] = useState<number>(1);

    useEffect(() => {
        console.log(userProfile);
        if (userProfile) {
            if (!userProfile.name || !userProfile.username) {
                setShow(true);
            } else {
                setShow(false);
            }
        }
    }, [userProfile]);

    useEffect(() => {
        console.log('here1');
    }, []);

    const handleNextPage = () => {
        setStep(step + 1);
    }

    return (
        <IonModal isOpen={show} canDismiss={!show} mode="ios">
            <IonPage>
                {/* <IonHeader>
                    <IonToolbar>
                        <IonTitle>Home</IonTitle>
                    </IonToolbar>
                </IonHeader> */}
                <IonContent fullscreen={true} color="light" className="ion-padding">
                    {/* <div className="content-block ion-padding"> */}
                        {
                            userProfile 
                                && step === 1 &&
                            <GatherUserInitialInfoComponent 
                                nameCurr={userProfile.name} 
                                usernameCurr={userProfile.username} 
                                redditUsernameCurr={userProfile.reddit_username}
                                closeModal={() => setShow(false)}
                                // goToNextPage={handleNextPage}
                                /> 
                        }
                        {/* {step === 2 &&
                            <AdditionalUserInfoComponent closeModal={() => setShow(false)} />
                    } */}
                    {/* </div> */}
                </IonContent>
            </IonPage>
        </IonModal>
    );
}

export default GatherInitialUserDataPopUp;