

import React, { useState, useEffect, useRef } from 'react';
import { IonButton,IonIcon  } from '@ionic/react';
import { Story, StoryItem } from "../../../../../../types/ai_pivot/contentTypes";
import QuizModule from '../quiz/QuizModule'; 
import LLMCheckModule from '../llmcheck/LLMCheckModule';
import DoDontModule from '../do_dont/DoDontModule';
import PairMatchingModule from '../pair_matching/PairMatchingModule';
import { ModuleProps } from "../shared/ModuleProps";
import AudioManager from '../../AudioManager';
import './StoryModule.css';
import '../shared/ModuleBottomBar.css';
import { playSkipForwardOutline } from 'ionicons/icons';

interface StoryModuleProps extends ModuleProps {
    story: Story;
}

const StoryModule: React.FC<StoryModuleProps> = ({ story, moveToNextModule, updateProgressBar }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(true);
    const [displayedTexts, setDisplayedTexts] = useState<string[]>([]);
    const [visibleMessages, setVisibleMessages] = useState<number[]>([]);
    const [isCheckMode, setIsCheckMode] = useState<boolean>(false);
    const typingTimeouts = useRef<NodeJS.Timeout[]>([]);
    const [completedModules, setCompletedModules] = useState<number[]>([]);
    const [lastModuleIndex, setLastModuleIndex] = useState<number | null>(null);

    const senderAlignments = useRef<{ [sender: string]: string }>({});
    let lastAlignment = 'right';
    
    useEffect(() => {
        if (story.items.length > 0) {
            handleStoryItem(story.items[0], 0);
        }
        return () => {
            clearTimeouts();
            AudioManager.stopCurrentAudio();
        };
    }, [story]);

    const clearTimeouts = () => {
        typingTimeouts.current.forEach(clearTimeout);
        typingTimeouts.current = [];
    };

    const handleStoryItem = async (item: StoryItem, index: number) => {
        if (item.type === 'message') {
            setVisibleMessages(prev => [...prev, index]);
            await playAudioAndTypeText(item.content.audio_url!, item.content.text!, index);
            setIsPlaying(false);
        } else if (item.type === 'module' && !completedModules.includes(index)) {
            setIsCheckMode(true);
            setLastModuleIndex(index);
        }
    };

    const playAudioAndTypeText = (audioUrl: string, text: string, index: number) => {
        return new Promise<void>((resolve) => {
            let typingCompleted = false;
            let audioCompleted = false;

            const onTypingComplete = () => {
                typingCompleted = true;
                if (audioCompleted) resolve();
            };

            const onAudioComplete = () => {
                audioCompleted = true;
                if (typingCompleted) resolve();
            };

            startTypingEffect(text, index, onTypingComplete);
            AudioManager.playAudio(audioUrl, onAudioComplete);
        });
    };

    const startTypingEffect = (text: string, index: number, onTypingComplete: () => void) => {
        clearTimeouts();

        let displayedText = '';
        setDisplayedTexts((prev) => {
            const newDisplayedTexts = [...prev];
            newDisplayedTexts[index] = '';
            return newDisplayedTexts;
        });

        for (let i = 0; i < text.length; i++) {
            const timeout = setTimeout(() => {
                displayedText += text[i];
                setDisplayedTexts((prev) => {
                    const newDisplayedTexts = [...prev];
                    newDisplayedTexts[index] = displayedText;
                    return newDisplayedTexts;
                });
                if (i === text.length - 1) {
                    onTypingComplete(); 
                }
            }, i * 35);

            typingTimeouts.current.push(timeout);
        }
    };

    const handleContinue = () => {
        let nextIndex = currentIndex + 1;

        while (nextIndex < story.items.length) {
            if (story.items[nextIndex].type === 'message' && !visibleMessages.includes(nextIndex)) {
                break;
            }
            if (story.items[nextIndex].type === 'module' && !completedModules.includes(nextIndex)) {
                break;
            }
            nextIndex++;
        }

        if (nextIndex < story.items.length) {
            setCurrentIndex(nextIndex);
            setIsPlaying(true);
            handleStoryItem(story.items[nextIndex], nextIndex);
        } else {
            setLastModuleIndex(null);
            moveToNextModule();
        }
    };

    const handleCheck = () => {
        setIsCheckMode(false);
        setCompletedModules(prev => [...prev, currentIndex]);
        setLastModuleIndex(null);
        handleContinue();
    };

    const replayAudio = (audioUrl: string, index: number) => {
        clearTimeouts(); 
        AudioManager.stopCurrentAudio(); 
    
        const maxIndex = Math.max(currentIndex, index);
    
        for (let i = 0; i <= maxIndex; i++) {
            if (story.items[i].type === 'message') {
                updateMessageState(i, {
                    text: story.items[i].content.text!, 
                });
                if (!visibleMessages.includes(i)) {
                    setVisibleMessages(prev => [...prev, i]);
                }
            }
        }
    
        if (lastModuleIndex === null || index >= lastModuleIndex) {
            setCurrentIndex(index);
        }
    
        setIsPlaying(true); 
        playAudioAndTypeText(audioUrl, story.items[index].content.text!, index)
            .then(() => {
                setIsPlaying(false);
            });
    };

    const updateMessageState = (index: number, newState: Partial<{ text: string }>) => {
        setDisplayedTexts((prev) => {
            const newDisplayedTexts = [...prev];
            newDisplayedTexts[index] = newState.text || newDisplayedTexts[index];
            return newDisplayedTexts;
        });
    };

    const determineAlignment = (sender: string, index: number) => {
        if (sender.toLowerCase() === 'author') {
                    return 'center';
                }
        if (!senderAlignments.current[sender]) {
            senderAlignments.current[sender] = lastAlignment === 'left' ? 'right' : 'left';
        }
    
        if (index > 0) {
            const previousItem = story.items[index - 1];
            const previousSender = previousItem.type === 'message' && previousItem.content?.sender
                ? previousItem.content.sender.toLowerCase()
                : null;
            
            if (previousSender && previousSender !== sender) {
                senderAlignments.current[sender] = lastAlignment === 'left' ? 'right' : 'left';
            }
        }
    
        lastAlignment = senderAlignments.current[sender];
        return senderAlignments.current[sender];
    };

    const handleSkip = () => {
        clearTimeouts();
        AudioManager.stopCurrentAudio();
    
        const nextModuleIndex = story.items.findIndex(
            (item, index) => item.type === 'module' && index > currentIndex && !completedModules.includes(index)
        );
    
        if (nextModuleIndex !== -1) {
            for (let i = currentIndex; i < nextModuleIndex; i++) {
                if (story.items[i].type === 'message') {
                    setVisibleMessages(prev => [...prev, i]);
                    updateMessageState(i, { text: story.items[i].content.text });
                }
            }
            setCurrentIndex(nextModuleIndex);
            setLastModuleIndex(nextModuleIndex);
            setIsCheckMode(true);
        } else {
            const lastMessageIndex = story.items.length - 1;
            for (let i = currentIndex; i < story.items.length; i++) {
                if (story.items[i].type === 'message') {
                    setVisibleMessages(prev => [...prev, i]);
                    updateMessageState(i, { text: story.items[i].content.text });
                }
            }
            // moveToNextModule();
            setCurrentIndex(lastMessageIndex);
            setIsPlaying(false);
        }
    };

    
    return (
        <div className="story-content">
            <div style={{ maxWidth: '650px', margin: 'auto', marginBottom: '12vh', padding: '20px' }} className="ion-padding">
                <h2 className="lesson-name">{story.name}</h2>

                {story.items.map((item, index) => {
                    if (item.type === 'message' && visibleMessages.includes(index)) {
                        const sender = item.content?.sender?.toLowerCase();
                        const alignment = sender ? determineAlignment(sender, index) : 'center';

                        console.log(`Displaying message at index ${index}`);

                        return (
                            <div key={index} className={`message-wrapper_s ${alignment}`}>
                                {sender !== 'author' && (
                                    <div className={`sender-name_s ${alignment}`}>
                                        {item.content?.sender}
                                    </div>
                                )}
                                <div
                                    className={`message_s ${alignment}`}
                                    onClick={() => replayAudio(item.content?.audio_url || '', index)}
                                >
                                    {displayedTexts[index] || ''}
                                </div>
                            </div>
                        );
                    } else if (item.type === 'module' && (index === lastModuleIndex || (index === currentIndex && !completedModules.includes(index)))) {
                        console.log(`Displaying module at index ${index}, check mode: ${isCheckMode}`);
                        return (
                            <div key={index} className="module-wrapper">
                                {item.content.module.type === 'quiz' && (
                                    <QuizModule quiz={item.content.module} moveToNextModule={handleCheck} updateProgressBar={updateProgressBar} hideButton={false} />
                                )}
                                {item.content.module.type === 'llm_check' && (
                                    <LLMCheckModule llmCheck={item.content.module} moveToNextModule={handleCheck} updateProgressBar={updateProgressBar} hideButton={false} />
                                )}
                                {item.content.module.type === 'do_dont' && (
                                    <DoDontModule doDont={item.content.module} moveToNextModule={handleCheck} updateProgressBar={updateProgressBar} hideButton={false} />
                                )}
                                {item.content.module.type === 'pair_matching' && (
                                    <PairMatchingModule pairMatching={item.content.module} moveToNextModule={handleCheck} updateProgressBar={updateProgressBar} hideButton={false} />
                                )}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>

            {/* {!isPlaying && !isCheckMode && (
                <div className="button-bar">
                    <div className="center-content">
                        <div className="bottom-btn-wrapper">
                            <IonButton onClick={handleContinue} expand="block">
                                Continue
                            </IonButton>
                        </div>
                    </div>
                </div>
            )} */}
            {!isCheckMode && (
                <div className="button-bar">
                    <div className="center-content">
                        <div className="bottom-btn-wrapper">
                            {isPlaying ? (
                                <IonButton 
                                    onClick={handleSkip} 
                                    
                                    className="skip-button"
                                    expand="block"
                                >
                                    Skip
                                </IonButton>
                            ) : (
                                <IonButton onClick={handleContinue} expand="block">
                                    Continue
                                </IonButton>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StoryModule;
