import { IonCard, IonButton, IonCardHeader, IonCardTitle, IonCardContent, IonText, IonCardSubtitle } from "@ionic/react";
import Feedback from "../home/Feedback";
import { FEEDBACK_SUMMARY_LOADING } from "../../../util/constants";
import { useState } from "react";
import AudioPlayer from 'react-h5-audio-player';
import './MyAudioCard.css';
import { useHistory } from "react-router-dom";
import '../../../theme/DuoLinguo.css';

const SitFeedback: React.FC<{feedback: string, title?: string, subtitle?: string, audio_link?:string, situationId?: number, showFeedbackButton?: boolean}> = ({feedback, title, subtitle, audio_link,situationId,showFeedbackButton  }) => {
    const [expandFeedback, setExpandFeedback] = useState(false);
    const history = useHistory();

    const handleSeeFeedback = () => {
        history.push(`/feedback/${situationId}`);
    };
    return (<IonCard
                mode="ios" className="custom-ion-card">
                <IonCardHeader>
                    {subtitle && <IonCardSubtitle style={{marginTop:10}}>{subtitle}</IonCardSubtitle>}
                    <IonCardTitle>{title ? title : 'Summary'}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent style={{marginTop:-10}}>
                    {
                        feedback === null ? '' : feedback === FEEDBACK_SUMMARY_LOADING
                            ?
                            <Feedback feedback={feedback} />
                            :
                            <>  
                                <Feedback feedback={expandFeedback ? feedback : feedback.slice(0, 300) + '...'} />
                                <IonText color="primary">
                                    <div onClick={() => feedback !== FEEDBACK_SUMMARY_LOADING 
                                        && setExpandFeedback(!expandFeedback)}>{expandFeedback ? 'Collapse' : 'Expand'}</div>
                                </IonText>
                            </>
                    }
                   
                   {showFeedbackButton && (
                    <div onClick={handleSeeFeedback} className="d-button"  >
                        <span className="d-button-text"> 
                            Feedback from other users
                        </span>
                    </div>
                )}
                    
                </IonCardContent>
            </IonCard>);
}

export default SitFeedback;